import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InfoForm from './InfoForm';
import InfoRandomByStateForm from './InfoRandomByStateForm';

function App() {
    return (
        <Router>
            <div style={{ fontFamily: 'sans-serif', margin: '2rem' }}>
                <Routes>
                    <Route path="/" element={<InfoForm />} />
                    <Route path="/random-by-state" element={<InfoRandomByStateForm />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
